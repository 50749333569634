@font-face {
  font-family: "Chomsky";
  src:
    url("../public/fonts/chomsky.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JosefinSans";
  src: url("../public/fonts/josefinsans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Curse";
  src: url("../public/fonts/curse.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}