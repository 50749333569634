$lapis-lazuli: #3c5e87ff;
$black-bean: #351116ff;
$steel-blue: #3b84b7ff;
$chocolate-cosmos: #530f16ff;
$candy-apple-red: #ff372cff;
$red-cmyk: #df0206ff;
$dec23: #f11e61ff;
$nye23: #F1AB33ff;
$jan24: #B41912ff;
$feb24: #FB1C1Cff;
$mar24: #ED2727ff;
$apr24: #ff005dFF;
$jun24: #F7EF82ff;
$jul24: #fa8734FF;
$aug24: #fd3b78FF;
$oct24: #F13373ff;
$nov24: #E03C36ff;
$nye24: #ECDE7Dff;
$feb25: #EE294Dff;
$mar25: #D34D37ff;

$main-accent: $mar25;
