@import "./Fonts.scss";
@import "./Palette.scss";

@mixin custom-scrollbar(
  $thumb-color: #555,
  $track-color: #2d2d2d,
  $thumb-hover-color: #444,
  $width: 10px
) {
  // For WebKit browsers (Chrome, Safari)
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $thumb-hover-color;
  }

  // For Firefox
  scrollbar-width: thin;
  scrollbar-color: $thumb-color $track-color;
}

@keyframes badgeFlash {
  0% {
    background-color: $main-accent;
    color: #ffffff;
  }
  50% {
    background-color: #ffffff;
    color: $main-accent;
  }
  100% {
    background-color: $main-accent;
    color: #ffffff;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.95)),
    url("../public/images/bg_pattern.png");
}

/* App.scss */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;

  &.logo-only {
    justify-content: center;
  }

  .logo {
    .logo-img {
      height: 90px;
      top: 50px;
      width: auto;

      @media (max-width: 480px) {
        height: 70px;
      }
    }
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .link {
      color: white;
      cursor: pointer;
      background-color: transparent;
      border: none;
      margin: 0 20px;
      font-size: 32px;
      transition: color 0.3s;
      font-family: "Chomsky", sans-serif, Helvetica, Arial;
      position: relative;
      display: inline-block;
      text-decoration: none;
      white-space: nowrap;

      &::before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: 0%;
        height: 2px;
        background-color: $main-accent;
        transition: width 0.3s ease-out;
      }

      &:hover {
        color: $main-accent;

        &::before {
          width: 85%;
        }
      }

      @media (hover: none) and (pointer: coarse) {
        &:hover {
          color: white;
          transition: none;
        }
        &::before {
          width: 0%;
          transition: none;
          display: none;
        }
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }

    .link-icon {
      cursor: pointer;
      color: white;
      margin: 0 20px;
      transition: color 0.3s;

      &:hover {
        color: $main-accent;
      }

      @media (hover: none) and (pointer: coarse) {
        &:hover {
          color: white;
          transition: none;
        }
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  .burger-menu {
    display: none;

    @media (max-width: 1000px) {
      display: block;
    }
  }
}

.content {
  height: calc(100dvh - 100px);
  display: flex;
  justify-content: center;
  margin-top: 100px;
  overflow-y: auto;
  background:
    linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.95)),
    url("../public/images/bg_pattern.png") scroll;

  @include custom-scrollbar;

  .section {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 90dvw;

    .header {
      height: 100px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;

      .header-container {
        font-family: "Curse", "Chomsky", sans-serif, Helvetica, Arial;
        font-size: 40px;
        font-weight: normal;
        color: $main-accent;
        margin: 20px 0 0 0;
        line-height: 65px;
        height: 65px;
      }
    }
  }

  .songs-table-container {
    max-height: 600px;
    height: calc(100dvh - 230px);
    margin: 0 0 10px 0;
    overflow: hidden;
    display: flex;
    max-width: 1000px;
    width: 100%;
    flex-direction: column;

    &.maximized {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100dvh;
      z-index: 1000;
      max-height: 100dvh;
    }

    .table-container {
      flex: 1; // makes it occupy the remaining space
      overflow-y: auto; // makes it scrollable
      background-color: #000000;

      table {
        border-collapse: collapse;
        width: 100%;

        tr {
          border-top: 1px solid #ffffff;
          border-bottom: 1px solid #ffffff;
          height: 40px;
          width: 100%;
          overflow: hidden;

          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: none;
          }
        }

        &,
        th,
        td {
          border: 1px solid black;
        }

        th,
        td {
          padding: 8px 12px;
          width: 50%;
          font-family: "JosefinSans", sans-serif, Helvetica, Arial;
          color: #ffffff;
          text-align: center;
          vertical-align: middle;

          &.new-song {
            .new-badge {
              display: flex;
            }
          }

          .new-badge {
            display: none;
            align-items: center;
            justify-content: center;
            padding: 4px 6px;
            border-radius: 4px;
            font-size: 11px;
            font-weight: bold;
            float: left;
            height: 11px;
            animation: badgeFlash 2s infinite;
          }

          &.artist {
            text-align: right;
          }

          &.title {
            text-align: left;
          }
        }

        th {
          color: $main-accent !important;
        }
      }

      @include custom-scrollbar;
    }

    .search-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      border: 1px solid #000000;
      border-bottom: 1px solid #ffffff;

      .search-field {
        flex: 1;
        background-color: #000000;
        font-family: "JosefinSans", sans-serif, Helvetica, Arial;
        color: #ffffff;
        height: 40px;
        padding: 0 20px;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;

        &:focus {
          outline: none;
        }
      }

      .search-clear,
      .maximize-app {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000000;

        &.hide {
          display: none;
        }

        .icon {
          cursor: pointer;
          color: white;
          height: 20px;
          width: 20px;
          transition: color 0.3s;
          &:hover {
            color: $main-accent;
          }

          @media (hover: none) and (pointer: coarse) {
            &:hover {
              color: white;
              transition: none;
            }
          }
        }
      }
    }
  }

  .event-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 100%;
    max-width: 1000px;

    @media (max-width: 1000px) {
      width: 90dvw;
    }

    .event-image {
      border: 1px solid transparent;
      border-radius: 5px;
      max-width: 750px;
      width: 750px;

      @media (max-width: 1000px) {
        width: 100% !important;
      }
    }

    .event-info {
      // margin-top: 20px;
      font-family: "JosefinSans", sans-serif, Helvetica, Arial;
      font-size: 24px;
      color: #ffffff;
      width: 100%;
      white-space: pre-line;
      padding: 20px 0;
    }

    .event-details {
      color: $main-accent;
    }

    .event-link {
      color: $main-accent;
      text-decoration: underline;
    }
  }

  .footer {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .copyright {
      font-family: "JosefinSans", sans-serif, Helvetica, Arial;
      text-align: center;
      color: #444444;
      font-size: 12px;
      line-height: 20px;
      padding: 0;
    }
  }
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;

  @media (max-width: 480px) {
    width: 22px;
    height: 22px;
    left: 22px;
    top: 36px;
  }
}

.bm-burger-bars {
  background: #ffffff;
}

.bm-menu {
  background: linear-gradient(rgba(0, 0, 0, 0.98), rgba(0, 0, 0, 0.98)),
    url("../public/images/bg_pattern.png");
  display: flex;
  justify-content: center;
  height: calc(100dvh - 100px) !important;
}

.bm-morph-shape {
  fill: $main-accent;
}

.bm-item-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #b8b7ad;
  align-items: center;
  overflow-y: auto;

  @include custom-scrollbar;
}

.bm-item {
  display: inline-block;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 30px 0;
  font-size: 42px;
  transition: color 0.3s;
  font-family: "Chomsky", sans-serif, Helvetica, Arial;
  position: relative;
  display: inline-block;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 2px;
    background-color: $main-accent;
    transition: width 0.3s ease-out;
  }

  &:hover {
    color: $main-accent;

    &::before {
      width: 85%;
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      color: white;
      transition: none;
    }
    &::before {
      width: 0%;
      transition: none;
      display: none;
    }
  }
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}